

export const ColorsData = [{
    color:"black",background:"black"
},{
    color:"blue", background:"blue"
},{
    color:"pink",background:"pink"
}, {
    color:"orange",background:"orange"
},{
    color: "red",background:"red"
},{
    color:"white",background:"white"
},{
    color:"yellow",background:"yellow"
},{
    color:"green",background:"green"
},{
    color:"rose",background:"#CEB4BE"
},{
    color:"cream", background:"#F0F0EF"
},{
    color:"mint", background: "#2CB695"
},{
    color:"cobalt", background:"#5591c9"
},{
    color:"camo", background:"#828382"
}]